<template>
  <div>
    <price-tiers-form
      v-if="priceTiers.id"
      ref="price-tiers-form"
    />
    <div v-else>
      <div v-if="!error">
        <b-row>
          <b-col md="6">
            <b-skeleton type="input" />
          </b-col>
          <b-col md="6">
            <b-skeleton type="input" />
          </b-col>
        </b-row>
        <b-row class="pt-2">
          <b-col md="6">
            <b-skeleton type="input" />
          </b-col>
          <b-col md="6">
            <b-skeleton type="input" />
          </b-col>
        </b-row>
      </div>
      <div v-else>
        <component-not-found
          title="Price Tiers List"
          :button-link="{ name: 'settings-catalog-price-list'}"
        />
      </div>
    </div>
  </div>

</template>

<script>

import {
  BRow, BCol, BSkeleton,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import PriceTiersForm from '../components/PricingTiersForm.vue'
import config from '../pricingConfig'

export default {
  name: 'PricingTierUpdate',
  components: {
    PriceTiersForm,
    BRow,
    BCol,
    BSkeleton,
  },
  inject: ['pricingTiersModuleName'],
  data() {
    return {
      error: false,
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs['price-tiers-form']) this.$refs['price-tiers-form'].showConfirmationModal(next)
  },
  computed: {
    priceTiers() {
      return this.$store.state[this.pricingTiersModuleName].priceTiersForm
    },
    priceTierTypes() {
      return this.$store.state.listModule.priceTiersType
    },
    ...mapGetters('listModule', ['getStatus']),
  },
  mounted() {
    this.$store.dispatch(`${this.pricingTiersModuleName}/get`, this.$route.params.id).then(res => {
      const { data } = res.data
      const priceTiers = this.mappingFields(Object.keys(this.fields), data, {
        id: data.id,
        currency_id: data.currency,
        type: this.priceTierTypes.find(type => type.id === data.type),
      })
      this.$store.commit(`${this.pricingTiersModuleName}/SET_FORM`, priceTiers)
      this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_SETTING_PRICING_TIERS_FORM_CLONE`, priceTiers)
    }).catch(err => {
      this.error = true
      this.errorNotification(this, err)
    })
  },
  created() {
    this.$store.commit(`${this.pricingTiersModuleName}/SET_FORM`, {})
  },
  setup() {
    const MODULE_NAME_CLONE = 'cloneData'
    const { fields } = config()
    return {
      MODULE_NAME_CLONE,
      fields,
    }
  },
}
</script>
